import { Link, withPrefix } from "gatsby";
import React from "react";
// import Image from "./Image";

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false
    };
  }

  overlay = () => {
    const url = `projects/${this.props.portfolio.slug}`;
    return (
      <div className="portfolio__overlay">
        <p>{this.props.portfolio.name}</p>
        <Link to={url}>read more</Link>
      </div>
    );
  };

  onMouseEnterHandler = () => {
    this.setState({
      isHover: true
    });
  };

  onMouseLeaveHandler = () => {
    this.setState({
      isHover: false
    });
  };

  render() {
    const { portfolio, slug } = this.props;
    return (
      <div className={`project-item-outer ${this.state.isHover ? "project-item-outer--hover" : ""}`} 
          style={{backgroundImage: `url(${withPrefix(portfolio.cover)})`, backgroundSize: `cover`}}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler} role="button" tabIndex="0">
        <div className="project-item-overlay">
          <span>{portfolio.title}{" "}</span>
          <Link className="" to={slug}>read more</Link>
        </div>
      </div>
    );
  }
}

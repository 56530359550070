import React from 'react'
import {  graphql } from 'gatsby'
// import { css } from "react-emotion"
import Layout from '../components/layout'
import Portfolio from '../components/portfolio'
import Clients from '../components/clients'


export default ({ data }) => (
  <Layout>
    <div className="px-4 lg:px-0 lg:mx-8 w-full lg:w-4/5 leading-loose text-xl text-color">
    Ideas are my bread n butter. I mold and shape them into living breathing <span className="text-highlight">interactive experiences</span> and <span className="text-highlight">captivate</span>. I believe that great interactive ideas drive on the tension between <span className="text-highlight">design</span> and <span className="text-highlight">code</span>.
    </div>
      {/* <ul className="list-reset flex flex-wrap mx-2 lg:mx-6 my-2 mt-12">
        <li className="mx-2"><a className="text-base text-black no-underline navigation-links active" href="#all">All</a></li>
        <li className="mx-2"><a className="text-base text-black no-underline navigation-links" href="#web">Web</a></li>
        <li className="mx-2"><a className="text-base text-black no-underline navigation-links" href="#mobile-app">Mobile Apps</a></li>
        <li className="mx-2"><a className="text-base text-black no-underline navigation-links" href="#photography">Photography</a></li>
        <li className="mx-2"><a className="text-base text-black no-underline navigation-links" href="#designs">Designs</a></li>
      </ul> */}
      <div className="home-project-grid mx-0 mt-16 lg:mx-6">
        <ul className="list-reset flex flex-wrap mx-0 text-color">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <li className="w-full sm:w-full md:w-1/2 lg:w-1/3 project-item px-0 lg:px-2" key={node.id}>
            <Portfolio slug={node.fields.slug} portfolio={node.frontmatter}/>
          </li>
        ))}
        </ul>
        <div className="my-4 mx-2">
          <Clients/>
        </div>
      </div>
      
  </Layout>
)


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        tagLine
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date]},
      filter: {fileAbsolutePath: {regex: "/(\\/projects)/.*.md$/"}}
    ){
      totalCount
      edges {
        node {
          tableOfContents(pathToSlugField: "frontmatter.path")
          fields {
            slug
          }
          wordCount {
            paragraphs
            sentences
            words
          }
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            cover
          }
          excerpt
          html
          headings {
            depth
            value
          }
        }
      }
    }
  }`